 body {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
 
}


/* src/index.css */
@import './styles/tailwind.css';
/* other styles or imports */


