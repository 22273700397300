.custom-ellipsis-speaker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popupscreen {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto !important;
  top: 0 !important;
  left: 0 !important;
  /* z-index: 99999 !important; */
  padding: 2em 0 !important;
  box-sizing: border-box !important;
  display: flex !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.2);
  border: #dedede;
}