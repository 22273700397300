.CfsIntergrate {}

.rti--container {
  // --rti-bg: #eee !important;
  --rti-border: #d1d5db !important;
  --rti-tag-remove: #fd5858 !important;
}

.tag-cls{
    background: #3e7ce8 !important;
    color: white !important;
}

.input-cls{
    background: none !important;
    width: -webkit-fill-available !important;
}

