.ml-sidebar {
    margin-left: 250px;
  }
  
  .ml-list-events {
    margin-left: 2px;
  }

  .maindiv{
    margin-left: 250px !important;
  }

  .sidebar {
    box-sizing: border-box;
  }

  *{
    box-sizing: border-box;
  }
  