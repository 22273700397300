.back-icon {
  border: 1px solid rgb(214, 196, 196);
  background-color: white;
  padding: 5px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.boxing {
  margin-top: 1em;
  padding: 1.5em;
  .header {
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(230, 224, 224);
    background-color: #625ffb;
    border-radius: 2px;
    padding: 5px;
    font-size: 15px;
    color: white;
  }
  .container-feild {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    .sub-title {
      margin-bottom: 0.7em;
      margin-top: 0.7em;
      color: #c1c6cc;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .sesion-update {
    margin-top: 1em;
    margin-bottom: 1em;
    color: #c1c6cc;
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }
  .speaker-details {
    margin-top: 1em;
  }
  .session-input {
    width: 45%;
    padding: 5px;
  }
  .session {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .update-heading {
    font-size: 1.3em;
    font-weight: bold;
  }
  .edit-svg {
    cursor: pointer;
    border: 2px solid rgb(255 181 69);
    border-radius: 50%;
    padding: 3px;
  }

  .rounded-circle {
    margin-top: 0.8em;
    border: 1px solid rgb(230, 224, 224);
    padding: 5px;
    border-radius: 50% !important;
    width: 8em;
    height: 8em;
  }
  .organization {
    display: flex;
    align-items: center;
    gap: 5em;
    margin-top: 2em;
  }
  .overall-container {
    margin-left: 0.6em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .speaker-flex {
    display: flex;
    gap: 9em;
  }
  .cursor-pointer {
    cursor: pointer;
    margin-top: 4px;
  }
  .no-speaker-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5em;
  }
}

.title {
  width: 100%;
  padding: 8px;
}
.description {
  width: 100%;
  padding-bottom: 16px;
  font-size: 14px;
  padding: 3px;
}

.sesion-update {
  margin-top: 1em;
  margin-bottom: 1em;
  color: #c1c6cc;
  font-size: 14px;
}
.update-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-btn {
  cursor: pointer;
  border: 1px solid rgb(155, 142, 142);
  border-radius: 50%;
}
.input {
  width: 45%;
  padding: 5px;
}
.butn {
  margin-top: 15px;
  width: 100%;
}
.session-track{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  gap: 5em;
  .input {
    width: 15rem;
    padding: 5px;
  }
}

.model-wrap {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999 !important;
  padding: 2em 0 !important;
  box-sizing: border-box !important;
  display: flex !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.2);
  border: #dedede;
  .modal-content {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    padding: 1em 0 0 !important;
    margin: auto !important;
    width: 50%;
    background: white !important;
    border-radius: 0.7rem !important;
    color: black !important;
    .modal-form {
      padding: 3em;
    }
  }
}
