.content-wrapper {
  width: 80%;
  margin: auto;
}

.custom-pointer {
  cursor: pointer;
}
.custom-font-size {
  font-size: 11px;
  padding: 6px;
  border-radius: 3px;
}
.status-decline {
  background-color: #dc2727;
  color: white;
}

.badge-success {
  background-color: #0c6636;
  color: white;
}
.custom-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
.profile {
  width: 2.2rem;
  height: 2.2rem;
}
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.navigate-btn {
  background-color: #1fb6ff;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 3px;
  font-size: 12px;
}
.no-session {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.session-filters {
  display: flex;
  justify-content: space-between;
}
.status-filter {
  width: 10em;
}

.status-filter-menu {
  position: absolute;
  padding: 14px;
  border-radius: 10px;
  background-color: rgb(247 245 245);
}
.option-filter {
  margin-top: 8px;
  margin-bottom: 8px;
}
.search-bar {
  width: 20em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ddd;
}

.pagination li.active a {
  background-color: #4598eb;
  color: white;
  border: 1px solid #4598eb;
  border-radius: 3px;
}

.pagination li a:hover {
  border-radius: 3px;
  background-color: #4598eb;
  color: white;
  border: 1px solid #4598eb;
}

.badge-primary {
  border: 1px solid #b66dff;
  background: #b66dff;
  color: #ffffff;
}

.badge-secondary {
  border: 1px solid #c3bdbd;
  background: #c3bdbd;
  color: #ffffff;
}

.badge-success,
.preview-list .preview-item .preview-thumbnail .badge.badge-online {
  border: 1px solid #1bcfb4;
  background: #1bcfb4;
  color: #ffffff;
}

.badge-info,
.preview-list .preview-item .preview-thumbnail .badge.badge-offline {
  border: 1px solid #198ae3;
  background: #198ae3;
  color: #ffffff;
}

.badge-warning,
.preview-list .preview-item .preview-thumbnail .badge.badge-busy {
  border: 1px solid #fed713;
  background: #fed713;
  color: #ffffff;
}

.badge-danger {
  border: 1px solid #fe7c96;
  background: #fe7c96;
  color: #ffffff;
}

.badge-light {
  border: 1px solid #f8f9fa;
  background: #f8f9fa;
  color: #ffffff;
}

.badge-dark {
  border: 1px solid #3e4b5b;
  background: #3e4b5b;
  color: #ffffff;
}
