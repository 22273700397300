.EditEvent {}

.custom-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }

.collapse-container {
    overflow: hidden;
  }
  
  .collapse-content {
    height: 0;
    overflow: hidden;
    transition: height 0.1s ease;
  }
  
  .collapse-content.open {
    height: max-content;
  }