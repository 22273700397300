.toggle-buttons button {
  color: lightgrey;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &.active {
    border-bottom-color: #fd993e;
    font-weight: bold;
  }
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.accordion-content.expanded {
  max-height: 200px;
}
