.SpeakerList {
}

.search-head {
  display: flex;
}
.search-icon {
  margin-top: 11px;
  margin-left: -30px;
}
.search-bar {
  right: 0;
  width: 50%;

  padding: 0.5rem;
  border: 1px solid #808080;
}
.search-bar::placeholder {
  font-size: 0.8em;
}
.search-bar:focus {
  outline: 1px solid #808080;
}
.custom-ellipsis-speaker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14em;
}
.custom-ellipsis-speaker-company {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16em;
}

.custom-speaker-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 460px;
}
